<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar size="100px" class="avatar-float" v-bind="attrs" v-on="on">
        <img alt="Avatar" :src="require(`@/assets/IMG_0122.png`)" />
      </v-avatar>
    </template>
    <span>Change Basemap Style</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "Nav",
};
</script>

<style scoped>
.avatar-float {
  box-shadow: rgba(0, 0, 0, 0.9) 0px 22px 70px 4px;
  cursor: pointer;
}
</style>