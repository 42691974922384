<template>
  <div class="map" id="map"></div>
</template>

<script>
import * as mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  name: "Map",

  props: {
    background: { type: String, required: true },
  },

  data() {
    return {
      map: null,
      workAreaTileURL:
        "https://s3-us-west-2.amazonaws.com/justinbyrne.ca/vector-tiles/work-area/{z}/{x}/{y}.pbf",
    };
  },

  computed: {
    mbglToken() {
      return "pk.eyJ1IjoiZGNhaXJucyIsImEiOiJja24xMmpubG8wanIwMndtaDV5cmJ1bXJsIn0.mEDKZuJBhLn40RdqGfJ-1Q";
    },
    studyAreaURL() {
      return "https://s3-us-west-2.amazonaws.com/justinbyrne.ca/vector-tiles/work-area/{z}/{x}/{y}.pbf";
    },
    mapOptions() {
      return {
        container: "map",
        center: [-123, 49.2],
        zoom: 10.5,
        pitch: 65,
        bearing: 80,
        style: this.background,
      };
    },
  },

  watch: {
    background(newBG) {
      this.map.setStyle(newBG);
    },
  },

  methods: {
    initMap() {
      // Initiate the map instance
      if (this.map) this.map.remove();
      this.map = null;

      mapboxgl.accessToken = this.mbglToken;
      this.map = new mapboxgl.Map(this.mapOptions);

      let self = this;
      this.map.on("load", () => {
        this.addLayers();
        this.map.on("style.load", function () {
          self.addLayers();
        });
      });
    },
    addLayers() {
      // Add a hillshade if the basemap is the default
      this.map.addSource("mapbox-dem", {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
      });
      this.map.setTerrain({
        source: "mapbox-dem",
        exaggeration: 1.5,
      });

      this.map.addSource("dem", {
        type: "raster-dem",
        url: "mapbox://mapbox.terrain-rgb",
      });
      this.map.addLayer(
        {
          id: "hillshading",
          source: "dem",
          type: "hillshade",
        },
        "waterway-river-canal-shadow"
      );

      // Work area
      this.map.addSource("work-area", {
        type: "vector",
        tiles: [this.workAreaTileURL],
      });
      this.map.addLayer({
        id: "work-area",
        type: "fill",
        source: "work-area",
        "source-layer": "justin",
        maxzoom: 13,
        layout: {},
        paint: {
          "fill-color": "#0080ff",
          "fill-opacity": 0.5,
        },
      });

      var layers = this.map.getStyle().layers;
      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === "symbol" && layers[i].layout["text-field"]) {
          labelLayerId = layers[i].id;
          break;
        }
      }
      this.map.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#aaa",

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },

        labelLayerId
      );
    },
  },

  mounted() {
    this.initMap();
  },
};
</script>

<style scoped>
.map {
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
