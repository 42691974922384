<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dark>
      <div class="d-flex align-center"></div>

      <v-spacer></v-spacer>

      <v-btn target="_blank" text>
        <span class="mr-2">Hello Justin</span>
      </v-btn>
    </v-app-bar> -->

    <v-main>
      <justin-map class="map" :background="background"></justin-map>
    </v-main>

    <div class="nav-button" @click="toggleBackground">
      <h1 class="text-lg-center">JKB</h1>
      <nav-button></nav-button>
    </div>
  </v-app>
</template>

<script>
import JustinMap from "./components/JustinMap";
import NavButton from "./components/NavButton";

export default {
  name: "App",

  components: {
    JustinMap,
    NavButton,
  },

  data: () => ({
    backgroundIndex: 0,
    backgrounds: [
      "mapbox://styles/mapbox/cjaudgl840gn32rnrepcb9b9g",
      "mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y",
    ],
  }),

  computed: {
    background() {
      return this.backgrounds[this.backgroundIndex];
    },
  },

  methods: {
    toggleBackground() {
      this.backgroundIndex =
        this.backgroundIndex + 1 >= this.backgrounds.length
          ? 0
          : this.backgroundIndex + 1;
    },
  },
};
</script>

<style scoped>
.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.nav-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
